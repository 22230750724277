import React, { useState, useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import { Layout } from "@/components/layout"
import SEO from "@/components/seo"
import { Form } from "@/components/form"
import { Article } from "@/components/article"

const BlogPost: React.VFC<PageProps<GatsbyTypes.BlogPostQuery>> = ({
  data,
  location,
}) => {
  const [url, setUrl] = useState("")

  useEffect(() => {
    setUrl(location.href)
  }, [])

  return (
    <Layout>
      <SEO
        title={data.microcmsBlog?.title}
        description={data.microcmsBlog?.excerpt}
        image={data.microcmsBlog?.img?.url}
        url={url}
      />
      <Article microcmsBlog={data.microcmsBlog} />
      <Form />
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query BlogPost($id: String) {
    microcmsBlog(id: { eq: $id }) {
      blogId
      title
      date(formatString: "YYYY/MM/DD HH:mm")
      body
      shortCodes {
        body
        code
        fieldId
      }
      block {
        fieldId
        qa_list {
          fieldId
          question
          answer
        }
      }
      img {
        url
      }
      excerpt
      category {
        name
      }
    }
  }
`
